// ExportPage.jsx
import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';
import ProjectIdeaForm from '../components/Form/ProjectIdeaForm';
import './ExportPage.css';

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

const ExportPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [aiAnalysis, setAiAnalysis] = useState(location.state?.aiAnalysis || {});
  const [editableText, setEditableText] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companySlogan, setCompanySlogan] = useState('');
  const [exportFormat, setExportFormat] = useState('PDF');
  const [email, setEmail] = useState('');
  const formRef = useRef(null);
  const [progress, setProgress] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (formRef.current && aiAnalysis.ProjectIdeaName) {
      formRef.current.updateFormData({ projectIdeaName: aiAnalysis.ProjectIdeaName });
    }
  }, [aiAnalysis]);

  const handleLogoUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5000000 && (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/webp")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a valid image file (PNG, JPEG, or WebP) less than 5MB in size");
    }
  };

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setProgress('Analyzing and updating...');
    const maxRetries = 3;
    let retryCount = 0;

    while (retryCount < maxRetries) {
      try {
        const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
        const response = await fetch(`${API_BASE_URL}/api/analyze-edits/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            currentAnalysis: aiAnalysis,
            newText: editableText
          }),
          credentials: 'include'
        });

        const responseData = await response.json();

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'An error occurred');
        }

        setAiAnalysis(responseData);
        
        // Reset the editable text area
        setEditableText('');
        setProgress('');
        setIsSubmitting(false);
        break; // Exit the loop if successful
      } catch (error) {
        retryCount++;
        if (retryCount === maxRetries) {
          setProgress('Failed to analyze and update. Please try again later.');
          setIsSubmitting(false);
          break;
        }
        setProgress(`Taking longer than expected. Retrying (${retryCount}/${maxRetries})...`);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
      }
    }
  };

  const handleExport = async () => {
    if (!isValidEmail(email)) {
      setProgress('Please enter a valid email address.');
      return;
    }
    setProgress('Generating documents...');
    try {
      const csrftoken = getCookie('csrftoken');

      const currentFormData = formRef.current ? formRef.current.getCurrentFormData() : null;

      if (!currentFormData) {
        setProgress('Failed to generate documents: No form data available');
        return;
      }

      const formattedFormData = {
        ...currentFormData,
        projectIdeaName: currentFormData.projectIdeaName,
        ExpectedBenefits: Array.isArray(currentFormData.expectedBenefits) 
          ? currentFormData.expectedBenefits 
          : [currentFormData.expectedBenefits],
        StakeholderImpact: Array.isArray(currentFormData.stakeholderImpact)
          ? currentFormData.stakeholderImpact
          : [currentFormData.stakeholderImpact],
        RisksChallenges: Array.isArray(currentFormData.risksChallenges)
          ? currentFormData.risksChallenges
          : [currentFormData.risksChallenges],
        FeedbackMetrics: Array.isArray(currentFormData.feedbackMetrics)
          ? currentFormData.feedbackMetrics
          : [currentFormData.feedbackMetrics],
        TargetedDepartment: Object.keys(currentFormData.targetedDepartment).filter(dept => currentFormData.targetedDepartment[dept])
      };

      const requestBody = {
        formData: formattedFormData,
        companyName,
        companySlogan,
        logo,
        email
      };

      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
      const response = await fetch(`${API_BASE_URL}/api/generate-document/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify({ ...requestBody, exportFormat }),
        credentials: 'include'
      });

      if (response.ok) {
        const blob = await response.blob();
        
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${companyName} - ${currentFormData.projectIdeaName}.${exportFormat.toLowerCase() === 'word' ? 'docx' : exportFormat.toLowerCase()}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        setProgress('Document generated and downloaded successfully');
      } else {
        setProgress(`Failed to generate ${exportFormat} document. Please try again.`);
      }
    } catch (error) {
      setProgress('An error occurred while generating the document. Please try again.');
    }
  };

  const handleEmail = async () => {
    if (!isValidEmail(email)) {
      setProgress('Please enter a valid email address.');
      return;
    }
    setProgress('Generating and emailing documents...');
    try {
      const csrftoken = getCookie('csrftoken');
      const currentFormData = formRef.current ? formRef.current.getCurrentFormData() : null;

      if (!currentFormData) {
        setProgress('Failed to generate and email documents: No form data available');
        return;
      }

      const formattedFormData = {
        ...currentFormData,
        projectIdeaName: currentFormData.projectIdeaName,
        ExpectedBenefits: Array.isArray(currentFormData.expectedBenefits) 
          ? currentFormData.expectedBenefits 
          : [currentFormData.expectedBenefits],
        StakeholderImpact: Array.isArray(currentFormData.stakeholderImpact)
          ? currentFormData.stakeholderImpact
          : [currentFormData.stakeholderImpact],
        RisksChallenges: Array.isArray(currentFormData.risksChallenges)
          ? currentFormData.risksChallenges
          : [currentFormData.risksChallenges],
        FeedbackMetrics: Array.isArray(currentFormData.feedbackMetrics)
          ? currentFormData.feedbackMetrics
          : [currentFormData.feedbackMetrics],
        TargetedDepartment: Object.keys(currentFormData.targetedDepartment).filter(dept => currentFormData.targetedDepartment[dept])
      };

      const requestBody = {
        formData: formattedFormData,
        companyName,
        companySlogan,
        logo,
        email
      };

      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';
      const response = await fetch(`${API_BASE_URL}/api/email-documents/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
        },
        body: JSON.stringify(requestBody),
        credentials: 'include'
      });

      if (response.ok) {
        setProgress('Documents generated and emailed successfully');
      } else {
        setProgress('Failed to email documents. Please try again.');
      }
    } catch (error) {
      setProgress('An error occurred while emailing the documents. Please try again.');
    }
  };

  return (
    <div className="export-page">
      <Header />
      <main>
        <section className="editable-section">
          <p>Please review the full details about your project idea below. If you want to add or modify any information, enter your changes in the box below. Our AI will analyze and update the relevant sections of your project form.</p>
          <textarea
            className="editable-text"
            value={editableText}
            onChange={(e) => setEditableText(e.target.value)}
            placeholder="Enter any additional details or modifications to your project idea here. Our AI will analyze and update the relevant sections of your project form."
          />
          <button onClick={handleSubmit} className="submit-button" disabled={isSubmitting}>
            {isSubmitting ? 'Processing...' : 'Analyze and Update'}
          </button>
          {progress && <p className="progress-message">{progress}</p>}
        </section>

        {aiAnalysis && Object.keys(aiAnalysis).length > 0 && (
          <ProjectIdeaForm ref={formRef} initialData={aiAnalysis} />
        )}
        <section className="company-info">
          <h2>Upload Company Information for document branding</h2>
          <div className="company-inputs">
            <input
              type="text"
              placeholder="Company name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Company slogan"
              value={companySlogan}
              onChange={(e) => setCompanySlogan(e.target.value)}
            />
            <input
              type="file"
              accept="image/png, image/jpeg, image/webp"
              onChange={handleLogoUpload}
              style={{ display: 'none' }}
              id="logo-upload"
            />
            <label htmlFor="logo-upload" className="upload-logo">
              <span className="upload-icon">↑</span> Upload Logo
            </label>
            <p className="logo-recommendation"><i>Adding logo improves professionalism of document, if possible a white or transparent background</i></p>
          </div>
          <div className="email-input">
          <input
            type="email"
            placeholder="Email address"
            value={email}
            onChange={handleEmailChange}
          />
          </div>
        </section>

        <section className="export-section">
          <select value={exportFormat} onChange={(e) => setExportFormat(e.target.value)}>
            <option value="PDF">PDF</option>
            <option value="Word">Word</option>
          </select>
          <button onClick={async () => {
            await handleExport();
            await handleEmail();
          }} className="export-button">
            Export and Email
          </button>
        </section>
      </main>
      <section className="new-idea-section">
        <button onClick={() => navigate('/')} className="new-idea-button">
          Upload Another Idea
        </button>
      </section>
    </div>
  );
};

export default ExportPage;