//ideaManagementContainer.jsx
import React, { useState } from 'react';
import ProjectIdeaDump from '../components/BrainDump/ProjectIdeaDump';
import { useNavigate } from 'react-router-dom';


// Function to interact with the Django API
const fetchAIAnalysis = async (idea) => {
  const ideaText = typeof idea === 'string' ? idea : idea.idea;
  const requestBody = { idea: ideaText };
  const response = await fetch(`${process.env.REACT_APP_API_BASE_URL.replace(/\/+$/, '')}/api/ai-analysis/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
    mode: 'cors',
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
  }

  return await response.json();
};

// Helper function to get CSRF token from cookies
function _getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function IdeaManagementContainer() {
  const navigate = useNavigate();
  const [_aiAnalysis, setAiAnalysis] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const validateIdeaObject = (ideaObject) => {
    if (
      !ideaObject ||
      !ideaObject.idea ||
      typeof ideaObject.idea !== 'string' ||
      ideaObject.idea.trim().length === 0
    ) {
      return false;
    }
    return true;
  };

  const handleBrainDumpSubmit = async (ideaObject) => {
    setIsLoading(true);
    setError(null);
    if (!validateIdeaObject(ideaObject)) {
      setIsLoading(false);
      setError('Invalid idea object. Please try again.');
      return;
    }
    try {
      const analysis = await fetchAIAnalysis(ideaObject);
      setAiAnalysis(analysis);
      
      // Check for insufficient sections
      const insufficientSections = Object.entries(analysis)
        .filter(([_key, value]) => {
          // Consider a section insufficient if it's empty, null, or just whitespace
          return !value || (typeof value === 'string' && value.trim().length === 0);
        })
        .map(([key]) => key);
      
      if (insufficientSections.length > 0) {
        // If there are insufficient sections, navigate to the SecondPage
        navigate('/second', { state: { aiAnalysis: analysis, insufficientSections } });
      } else {
        // If all sections are sufficient, navigate directly to the ExportPage
        navigate('/export', { state: { aiAnalysis: analysis } });
      }
    } catch (error) {
      setError('Failed to process AI analysis. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <ProjectIdeaDump 
        onSubmit={handleBrainDumpSubmit} 
        isLoading={isLoading} 
        error={error}
      />
    </div>
  );

}

export default IdeaManagementContainer;