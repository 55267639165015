//ProjectIdeaDump.jsx
import React, { useState } from 'react';
import Button from '../Buttons/Button';
import PropTypes from 'prop-types';
import './ProjectIdeaDump.css';

function ProjectIdeaDump({ onSubmit, isLoading, error }) {
  const [projectIdea, setProjectIdea] = useState('');

  const handleProjectIdeaChange = (e) => {
    const value = e.target.value;
    if (value.length <= 5000) {
      setProjectIdea(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ idea: projectIdea });
  };

  return (
    <form onSubmit={handleSubmit} className="ProjectIdeaDump">
      <div className="form-group">
        <h3>Welcome to the Project Idea "Brain Dump"</h3>
        <h4>What is this?</h4>
        <div className="first-question">
          <p>
          This tool is designed to help you unload your project ideas in an unstructured format. 
          Simply jot down everything that comes to mind about a potential project—no detail is too small or too vague. Currently, 
          it’s tailored for pitching project ideas to your boss, management, or colleagues within your workplace
          </p>
        <h4>How it works:</h4>
        <p>
        Start by providing a brain dump of your idea. Write down everything you can think about your project. 
        Don't worry about missing details in certain sections; we'll help piece everything together based on the context you provide.
        </p>
        <h4>What you'll get:</h4>
        <p>
        You will receive a structured outline of your project idea in either a PDF or Word document and both will be emailed to you, 
        which you can use to pitch to your boss or other stakeholders, refine further, or develop into a full project plan.
        </p>
          <h4>Overview</h4>
          <ul className="bullet-list">
            <li>Which department(s) will be involved?</li>
            <li>What problem does this solve in the company?</li>
            <li>What improvements are you proposing?</li>
            <li>Are there any changes needed to current systems or processes?</li>
            <li>What is the priority level of this project (immediate need, medium-term goal, long-term vision)?</li>
          </ul>
          <h3>Project Scope</h3>
          <ul className="bullet-list">
            <li>What is the rough timeline and what are the key steps to completion?</li>
            <li>Will this project require financial investment?</li>
            <li>Who will you need for this project, and who will need to be trained or debriefed?</li>
          </ul>
          <h3>Outcomes</h3>
          <ul className="bullet-list">
            <li>What benefits do you expect this idea will bring to the organisation?</li>
            <li>Which stakeholders will be mainly affected upon completion, and how?</li>
            <li>What are the main risks and challenges associated with implementing this idea?</li>
          </ul>
          <p><em><strong>Remember:</strong> Your input doesn't need to be perfectly articulated or 
            grammatically correct—a true brain dump is all about getting your ideas out there!</em></p>
          </div>
        <textarea
          id="projectIdea"
          value={projectIdea}
          onChange={handleProjectIdeaChange}
          placeholder="Project idea brain dump example:
          
          I work for the local council and I would like to propose a project to create a mobile app designed to connect individuals with community gardens in their area. This app would enable users to discover local gardens, participate in events, and share resources like tools or seeds. It would be practical for city dwellers interested in gardening and community engagement,"
          disabled={isLoading}
          maxLength={5000}
        />
        {error && <p className="error-message">{error}</p>}
        <Button type="submit" disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Submit'}
        </Button>
        {isLoading && <div className="loading-spinner">Processing your idea...</div>}
      </div>
    </form>
  );
}

ProjectIdeaDump.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

export default ProjectIdeaDump;