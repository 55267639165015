import React from 'react';
import './Header.css';  // Make sure the CSS file name matches

function Header() {
  return (
    <div className="harmoni-header">
      <header>
        <div className="logo-container">
          <img src="Logo.png" alt="logo" className="logo" />
          <h1>Harmoni</h1>
        </div>
        <div className="creator-info">
          <p className="creator-name">Made by Arron Knight</p>
          <a href="mailto:Arronknight@proton.me" className="creator-email">
            ArronKnight@proton.me
          </a>
        </div>
      </header>
    </div>
  );
}

export default Header;