import React, { useState } from 'react';
import Header from '../components/Header/Header';
import IdeaManagementContainer from './IdeaManagementContainer';
import './HomePage.css';

function HomePage() {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  return (
    <div className="home-page">
      <Header />
      <main>
        <div className="platform-explanation">
          <h2 onClick={toggleExpand} className="subheading expandable-header">
            Harmoni platform explanation {isExpanded ? '▲' : '▼'}
          </h2>
          {isExpanded && (
            <div className="explanation-content">
              <h3 className="subheading">What is Harmoni ?</h3>
              <p className="opening-text">
                <strong>Harmoni is an 'innovation management platform' that sources project ideas from employees.</strong>
                <br />
                We help your team easily capture, iterate, and share their ideas and allow managers to quickly evaluate and implement them, 
                streamlining the process from initial concept to project execution.
                <br />
                Harmoni provides a positive incentive for both employees and the company. 
                Harmoni implements clear rewards for employees who submit high-quality ideas and for managers who implement them. 
                Companies take advantage of their greatest resource—their people—to innovate and grow.
                <br />
                <strong>Giving employees a voice through idea generation.</strong>
              </p>
              <h3 className="subheading">Current Harmoni Build 'MVP'</h3>
              <p className="opening-text">
                This build of Harmoni is the 'MVP', essentially an extremley basic version of the platform. designed to be a proof of concept 
                and to show the value and potential of the full platform before finishing it.
              </p>
              <h4 className="smallheading">Current Features</h4>
              <ul className="current-features">
                <li>Idea submission portal, 'Brain Dump'</li>
                <li>Harmoni AI to help and enhance idea generation and project categorisation</li>
                <li>Basic review and feedback systems</li>
                <li>Automatic project documentation formatting and download and E-mail</li>
              </ul>
              <h3 className="subheading">Coming Soon:</h3>
              <p className="opening-text">
                At the point of full launch you can look forward to:
                <br />
                For employees:
              </p>
              <ul className="current-features">
                <li>Idea submission portal with AI integration</li>
                <li>Active submissions tracking</li>
                <li>Project idea review page for each stage of the review/integration pipeline</li>
                <li>Rewards for for your project reaching each stage of the pipeline</li>
                <li>Participate in 'Innovation Challenges' set out by the company to provide solutions to specific problems</li>
                <li>Innovation leaderboard to congradulate the top innovators in the company</li>
              </ul>
              <p className="opening-text">
                For managers:
              </p>
              <ul className="current-features">
                <li>Clear and easy to use project idea tracking from your team</li>
                <li>Ability to set innovation challenges for your team adn the whole comapany</li>
                <li>Customised idea review pages at each stage of the pipeline to make your review process clear, quick and easy</li>
                <li>AI integration to provide you assistance throughout the review process</li>
                <li>Set your own rewards for employees who submit high-quality ideas and for managers who implement them</li>
                <li>View and recieve your own rewards for your contributions toward the innovation of your comapany</li>
              </ul>
            </div>
          )}
        </div>
        <IdeaManagementContainer />
      </main>
    </div>
  );
}

export default HomePage;