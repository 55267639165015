//ProjectIdeaForm.jsx
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './ProjectIdeaForm.css';
import PropTypes from 'prop-types';



const ProjectIdeaForm = forwardRef(({ initialData }, ref) => {
  const [formData, setFormData] = useState({
    projectIdeaName: '', // Added projectIdeaName field
    ideaDescription: '',
    targetedDepartment: {},
    priorityLevel: '',
    expectedBenefits: [{ summary: '', details: '' }],
    risksChallenges: [{ summary: '', details: '' }],
    stakeholderImpact: [{ summary: '', details: '' }],
    feedbackMetrics: [{ summary: '', details: '' }],
    problemSolving: '',
    improvementsIntegration: '',
    roughTimeline: '',
    stepsToCompletion: '',
    budgetFinancing: '',
    personnelRequirements: '',
    supportingDocuments: ''
  });

  useEffect(() => {
    if (initialData) {
      const processNestedData = (data) => {
        if (Array.isArray(data)) {
          return data.map(item => ({
            summary: item.Summary || '',
            details: item.Details || ''
          }));
        }
        return [{ summary: '', details: '' }];
      };

      setFormData(prevData => ({
        ...prevData,
        projectIdeaName: initialData.ProjectIdeaName || '', // Added projectIdeaName
        ideaDescription: initialData.IdeaDescription || '',
        targetedDepartment: initialData.TargetedDepartment ? initialData.TargetedDepartment.reduce((acc, dept) => ({ ...acc, [dept]: true }), {}) : {},
        priorityLevel: initialData.PriorityLevel || '',
        expectedBenefits: processNestedData(initialData.ExpectedBenefits),
        risksChallenges: processNestedData(initialData.RisksChallenges),
        stakeholderImpact: processNestedData(initialData.StakeholderImpact),
        feedbackMetrics: processNestedData(initialData.FeedbackMetrics),
        problemSolving: initialData.ProblemSolving || '',
        improvementsIntegration: initialData.ImprovementsIntegration || '',
        roughTimeline: initialData.RoughTimeline || '',
        stepsToCompletion: initialData.StepsToCompletion || '',
        budgetFinancing: initialData.BudgetFinancing || '',
        personnelRequirements: initialData.PersonnelRequirements || '',
        supportingDocuments: initialData.SupportingDocuments ? initialData.SupportingDocuments.join(', ') : ''
      }));
    }
  }, [initialData]);

  useImperativeHandle(ref, () => ({
    getCurrentFormData: () => {
      return formData;
    },
    updateFormData: (newData) => {
      setFormData(prevData => {
        const updatedData = { ...prevData, ...newData };
        return updatedData;
      });
    }
  }));

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => {
      const updatedData = { ...prevData };
      if (name.includes('[')) {
        const [arrayName, indexStr, field] = name.match(/([^[]+)\[(\d+)\]\.(.+)/).slice(1);
        const index = parseInt(indexStr, 10);
        updatedData[arrayName] = [...prevData[arrayName]];
        updatedData[arrayName][index] = { ...updatedData[arrayName][index], [field]: value };
      } else if (name.includes('.')) {
        const [parent, child] = name.split('.');
        updatedData[parent] = { ...updatedData[parent], [child]: value };
      } else {
        updatedData[name] = value;
      }
      return updatedData;
    });
  };

  const renderTextArea = (name, label, value) => (
    <div className="form-column">
      <label>{label}</label>
      <textarea
        name={name}
        value={value || ''}
        onChange={handleChange}
        className='textarea1'
      />
    </div>
  );

  const renderNestedTextAreas = (name, label, dataArray = []) => (
    <div className="form-column nested-text-areas">
      <label>{label}</label>
      {dataArray.map((data, index) => (
        <div key={index} className="nested-text-area-row">
          <textarea
            name={`${name}[${index}].summary`}
            value={data.summary || ''}
            onChange={handleChange}
            placeholder={`Summary ${index + 1}`}
            className="summary-textarea"
          />
          <textarea
            name={`${name}[${index}].details`}
            value={data.details || ''}
            onChange={handleChange}
            placeholder={`Details ${index + 1}`}
            className="details-textarea"
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="project-idea-form">
      <h2 onClick={toggleExpand}>Project Idea {isExpanded ? '▲' : '▼'}</h2>
      {isExpanded && (
        <div className="form-content">
          <form onSubmit={(e) => { e.preventDefault(); }}>
            <h3>Overview</h3>
            <div className="form-row">
              {renderTextArea('projectIdeaName', 'Project Idea Name', formData.projectIdeaName)}
              {renderTextArea('ideaDescription', 'Idea description', formData.ideaDescription)}
            </div>
            <div className="form-row">
              <div className="form-column">
                <label>Targeted Department:</label>
                {Object.entries(formData.targetedDepartment).map(([dept, isChecked]) => (
                  <div key={dept}>
                    <input
                      type="checkbox"
                      name={`targetedDepartment.${dept}`}
                      checked={isChecked}
                      onChange={(e) => handleChange({ target: { name: `targetedDepartment.${dept}`, value: e.target.checked } })}
                    />
                    <label>{dept}</label>
                  </div>
                ))}
              </div>
              <div className="form-column">
                <label>Priority level:</label>
                <select name="priorityLevel" value={formData.priorityLevel || ''} onChange={handleChange}>
                  <option value="">Select Priority</option>
                  <option value="Immediate need">Immediate need</option>
                  <option value="Medium-term goal">Medium-term goal</option>
                  <option value="Long-term vision">Long-term vision</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              {renderTextArea('problemSolving', 'What problem is this solving ?', formData.problemSolving)}
              {renderTextArea('improvementsIntegration', 'Improvements and integration needs', formData.improvementsIntegration)}
            </div>

            <h3>Project Scope</h3>
            <div className="form-row">
              {renderTextArea('roughTimeline', 'Rough Timeline', formData.roughTimeline)}
              {renderTextArea('stepsToCompletion', 'Project steps to completion', formData.stepsToCompletion)}
            </div>
            <div className="form-row">
              {renderTextArea('budgetFinancing', 'Budget (Financing)', formData.budgetFinancing)}
              {renderTextArea('personnelRequirements', 'Personnel Requirements & Affected Personnel', formData.personnelRequirements)}
            </div>

            <h3>Outcomes</h3>
            <div className="form-row">
              {renderNestedTextAreas('expectedBenefits', 'Expected Benefits', formData.expectedBenefits)}
            </div>
            <div className="form-row">
              {renderNestedTextAreas('stakeholderImpact', 'Stakeholder Impact', formData.stakeholderImpact)}
            </div>
            <div className="form-row">
              {renderNestedTextAreas('risksChallenges', 'Risks and Challenges', formData.risksChallenges)}
            </div>
            <div className="form-row">
              {renderNestedTextAreas('feedbackMetrics', 'Success/Feedback Metrics', formData.feedbackMetrics)}
            </div>

            <h3>Additional</h3>
            {renderTextArea('supportingDocuments', 'Supporting Documentation or References', formData.supportingDocuments)}
          </form>
        </div>
      )}
    </div>
  );
});


ProjectIdeaForm.defaultProps = {
  formData: {
    projectIdeaName: '', // Added projectIdeaName field
    ideaDescription: '',
    targetedDepartment: {
      Sales: false,
      BusinessDevelopment: false,
      Operations: false,
      HR: false,
      Finance: false,
      Marketing: false,
      Other: ''
    },

    problemSolving: '',
    improvementsIntegration: '',
    priorityLevel: '',
    roughTimeline: '',
    stepsToCompletion: '',
    budgetFinancing: '',
    personnelRequirements: '',
    expectedBenefits: { summary: '', details: '' },
    stakeholderImpact: { summary: '', details: '' },
    risksChallenges: { summary: '', details: '' },
    feedbackMetrics: { summary: '', details: '' },
    supportingDocuments: ''
  }
};

ProjectIdeaForm.propTypes = {
  initialData: PropTypes.shape({
    ProjectIdeaName: PropTypes.string,
    IdeaDescription: PropTypes.string,
    TargetedDepartment: PropTypes.arrayOf(PropTypes.string),
    PriorityLevel: PropTypes.string,
    ExpectedBenefits: PropTypes.arrayOf(PropTypes.shape({
      Summary: PropTypes.string,
      Details: PropTypes.string
    })),
    RisksChallenges: PropTypes.arrayOf(PropTypes.shape({
      Summary: PropTypes.string,
      Details: PropTypes.string
    })),
    StakeholderImpact: PropTypes.arrayOf(PropTypes.shape({
      Summary: PropTypes.string,
      Details: PropTypes.string
    })),
    FeedbackMetrics: PropTypes.arrayOf(PropTypes.shape({
      Summary: PropTypes.string,
      Details: PropTypes.string
    })),
    ProblemSolving: PropTypes.string,
    ImprovementsIntegration: PropTypes.string,
    RoughTimeline: PropTypes.string,
    StepsToCompletion: PropTypes.string,
    BudgetFinancing: PropTypes.string,
    PersonnelRequirements: PropTypes.string,
    SupportingDocuments: PropTypes.arrayOf(PropTypes.string)
  })
};


export default ProjectIdeaForm;